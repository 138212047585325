@if (loading$ | async) {

<div class="flex justify-center items-center h-screen w-screen">
  <mat-spinner></mat-spinner>
</div>

} @else if (authService.userSignal()?.uid) { @defer (on idle) {
<app-top-bar
  [collapsedSignal]="collapsed()"
  (collapsed)="collapsed.set($event)"
/>
<mat-sidenav-container class="h-[calc(100vh-64px)]">
  <mat-sidenav
    (closed)="collapsed.set(true)"
    [opened]="!isMobile() || !collapsed()"
    [mode]="!isMobile() ? 'side' : 'over'"
    [style.width]="sideNavWidth()"
  >
    <app-sidenav
      (linkClicked)="hideSidenav()"
      [isMobile]="isMobile()"
      [collapsed]="collapsed()"
    />
  </mat-sidenav>
  <mat-sidenav-content
    class="bg-slate-200"
    [style.margin-left]="!isMobile() ? sideNavWidth() : 0"
  >
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
} } @else {
<router-outlet></router-outlet>
}
