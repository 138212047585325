import { Component, inject } from '@angular/core';
import { MatToolbar } from '@angular/material/toolbar';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { AuthService } from '../../../core/services/auth.service';

@Component({
  selector: 'app-student-landingpage',
  standalone: true,
  imports: [MatToolbar, MatButton, MatIcon],
  templateUrl: './student-landingpage.component.html',
  styleUrl: './student-landingpage.component.scss',
})
export class StudentLandingpageComponent {
  authService = inject(AuthService);
}
