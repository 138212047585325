import { Component, computed, inject, signal } from '@angular/core';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SidenavComponent } from './private/components/ui/sidenav/sidenav.component';
import { AuthService } from './core/services/auth.service';
import { UserService } from './core/services/user.service';
import { User } from '@angular/fire/auth';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TopBarComponent } from './private/components/ui/top-bar/top-bar.component';
import { Subject } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { SnackbarService } from './core/services/snackbar.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    RouterLink,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    SidenavComponent,
    MatProgressSpinnerModule,
    TopBarComponent,
    AsyncPipe,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  router = inject(Router);
  authService = inject(AuthService);
  userService = inject(UserService);
  snackbarService = inject(SnackbarService);
  breakpointObserver = inject(BreakpointObserver);
  title = inject(Title);

  collapsed = signal(true);
  isMobile = signal(false);
  sideNavWidth = computed(() => {
    if (this.isMobile()) {
      return this.collapsed() ? '0px' : '240px';
    }
    return this.collapsed() ? '65px' : '240px';
  });
  layoutChanges = this.breakpointObserver.observe([
    Breakpoints.XSmall,
    Breakpoints.Small,
  ]);

  private loadingSubject = new Subject<boolean>();
  public loading$ = this.loadingSubject.asObservable();

  ngOnInit() {
    this.title.setTitle('Musikläraren');
    this.authService.user$.subscribe((user: User) => {
      this.handleUserLogin(user);
    });
    this.layoutChanges.subscribe((result) => {
      result.matches ? this.isMobile.set(true) : this.isMobile.set(false);
    });
  }

  handleUserLogin(user: User) {
    if (user) {
      this.loadingSubject.next(true);
      user
        .getIdTokenResult(true)
        .then((tokenResult) => {
          this.loadUserData()
            .then((userData) => {
              this.authService.userSignal.set({
                displayName: user.displayName!,
                email: user.email!,
                photoURL: user.photoURL!,
                uid: user.uid,
                description: userData?.['description'],
                unreadNotifications: userData?.['unreadNotifications'] || 0,
                role: tokenResult.claims['role'] as string,
                courses: userData?.['courses'],
              });
            })
            .catch((error) => {
              console.log('Could not load user data:', error);
              this.snackbarService.openSnackBar(
                'Vi kunde inte hitta din användare, är du säker på att du har skapat ett konto?',
                5000
              );
            })
            .finally(() => {
              this.loadingSubject.next(false);
              // this.router.navigate(
              //   this.authService.userSignal()?.role === 'student'
              //     ? ['/elev/hem']
              //     : ['/larare/hem']
              // );
            });
        })
        .catch((error) => {
          console.error('Could not get token result:', error);
          this.snackbarService.openSnackBar(
            'Kunde inte hämta användaren från google.',
            5000
          );
        });
    } else if (!user) {
      this.loadingSubject.next(false);
    }
  }

  loadUserData() {
    return this.userService.getUserData();
  }

  hideSidenav() {
    if (this.isMobile()) {
      this.collapsed.set(true);
    }
  }
}
