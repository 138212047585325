<mat-toolbar class="flex justify-between relative z-10 shadow-md">
  <div class="flex items-center text-lg">
    <img src="assets/img/logo.png" alt="Musikläraren" class="mr-2 h-8 w-8" />
    <span class="text-lg">Musikläraren <sup>ALPHA</sup></span>
  </div>
  <button mat-button (click)="authService.signInWithGoogle('student')">
    <span>Logga in</span>
    <mat-icon>login</mat-icon>
  </button>
</mat-toolbar>
<div class="flex flex-col h-[calc(100vh-64px)] justify-center items-center">
  <h1 class="text-6xl mb-6">Elev</h1>
  <button mat-button (click)="authService.signUpWithGoogle('student')">
    Skapa ett konto
  </button>
</div>
