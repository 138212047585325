import { Routes } from '@angular/router';
import { redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { LandingpageComponent } from './public/pages/teacher-landingpage/landingpage.component';
import { StudentLandingpageComponent } from './public/pages/student-landingpage/student-landingpage.component';

const redirectUnauthorizedToLanding = () => redirectUnauthorizedTo(['start']);

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'start',
    pathMatch: 'full',
    data: { authGuardPipe: redirectUnauthorizedToLanding },
    title: 'Musikläraren',
  },
  {
    path: 'elev-start',
    component: StudentLandingpageComponent,
    pathMatch: 'full',
  },
  { path: 'start', component: LandingpageComponent, pathMatch: 'full' },
  {
    path: 'larare',
    loadChildren: () =>
      import('./private/teacher/teacher.routes').then((m) => m.routes),
  },
  {
    path: 'elev',
    loadChildren: () =>
      import('./private/student/student.routes').then((m) => m.routes),
  },
];
