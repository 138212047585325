import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { routes } from './app.routes';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { environment } from '../environments/environment.dev';
import { provideHttpClient } from '@angular/common/http';
import { provideNativeDateAdapter } from '@angular/material/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

const firebaseEnvironment = environment;

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(),
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    provideRouter(routes),
    provideFirestore(() => getFirestore()),
    provideFirebaseApp(() => initializeApp(firebaseEnvironment)),
    provideAuth(() => getAuth()),
    provideAnimationsAsync(),
    provideNativeDateAdapter(),
  ],
};
